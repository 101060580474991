import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Login from './Login'

import './HomePage.css'

function HomePage() {
    const { t } = useTranslation();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    useEffect(() => {
        document.title = 'Lowner.dev - Authentication';

        const backgroundImages = [
            '/data/images/background_01.jpg',
            '/data/images/background_02.jpg',
            '/data/images/background_03.jpg',
            '/data/images/background_04.jpg',
            '/data/images/background_05.jpg',
            '/data/images/background_06.jpg'
        ];

        const randomImage = backgroundImages[Math.floor(Math.random() * backgroundImages.length)];

        const backgroundElement = document.querySelector('.auth-container');
        if (backgroundElement) {
        backgroundElement.style.backgroundImage = `url(${randomImage})`;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/check-auth`, { withCredentials: true, credentials: 'include'})
        .then(response => {
            if(response.data.isAuthenticated)
            {
                setIsAuthenticated(true);
                window.location.href = "/profile";
            }
        })
        .catch(error => {
            console.error('Authentication check error: ', error);
        });
    });

    if(isAuthenticated) {
        return (
            <div className="auth-container">
                <div className='main-content'>
                    <div className="logo-container">
                        <a href="https://lowner.dev" className="logo"><img src="/data/images/lownerLogo.png" alt="LownerLogo"/></a>
                    </div>
                    <div className="auth-content">
                        <p>{t('Redirection')}</p>
                    </div>
                </div>
                <footer className="auth-footer">
                    <p className='footer-left'>Lowner.dev is not affiliated or authorized by Bohemia Interactive a.s. Bohemia Interactive, ARMA, DAYZ and all associated logos and designs are trademarks or registered trademarks of Bohemia Interactive a.s.</p>
                    <p className='footer-right'>© {(new Date().getFullYear())} Lowner.dev. All rights reserved.</p>
                </footer>
            </div>
        );
    }

    return (
        <div className="auth-container">
            <div className='main-content'>
                <div className="logo-container">
                    <a href="https://lowner.dev" className="logo"><img src="/data/images/lownerLogo.png" alt="LownerLogo"/></a>
                </div>
                <div className="auth-content">
                    <h1>{t('Welcome to')} <span>auth.lowner.dev</span></h1>
                    <p>{t('To continue')}</p>
                    <Login className="LogInButton"/>
                </div>
            </div>
            <footer className="auth-footer">
                <p className='footer-left'>Lowner.dev is not affiliated or authorized by Bohemia Interactive a.s. Bohemia Interactive, ARMA, DAYZ and all associated logos and designs are trademarks or registered trademarks of Bohemia Interactive a.s.</p>
                <p className='footer-right'>© {(new Date().getFullYear())} Lowner.dev. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default HomePage;