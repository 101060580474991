import { useTranslation } from 'react-i18next';
import './Login.css'

function Login() {
    const { t } = useTranslation();

    const handleLogin = () => {
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/steam`;
    };

    return (
        <div>
            <br/>
            <button onClick={handleLogin} className="steambutton"><span>{t('SteamLogin')}</span><div className="icon">
		    <i className="fa-brands fa-steam fa-2xl"></i>
            </div></button>
        </div>
    );
}

export default Login;