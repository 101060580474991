import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';

import BackHeader from "./BackHeader";
import IpForm from "./IpForm";
import IpList from "./IpList";
import Footer from './Footer';

import './ProductIPPage.css';

function isValidIp(ip) {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipRegex.test(ip);
}

function formatIp(input) {
    return input.replace(/:\d+$/, '');
}

function ProductIpPage({ discordId }) {
    const { productName } = useParams();
    const decodedProductName = decodeURIComponent(productName);
    const [ips, setIps] = useState([]);

    useEffect(() => {
        document.title = 'User Profile - ' + decodedProductName;

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/product-ips`, { productName: decodedProductName }, { withCredentials: true, credentials: 'include'})
        .then(response => {
            setIps(response.data);
        })
        .catch(error => console.error('Error fetching IPs: ', error));
    }, [decodedProductName])

    const addIp = (newIpName, newIp) => {
        const formattedIp = formatIp(newIp);
        if(!isValidIp(formattedIp)) {
            alert('Please enter a valid IP adress.');
            return;
        }

        const serverNameToSend = newIpName.trim() === '' ? 'Empty' : newIpName;

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/ips`, { discordId, productName: decodedProductName, ip: formattedIp, serverName: serverNameToSend }, { withCredentials: true, credentials: 'include'})
        .then(response => {
            setIps([...ips, { ip: formattedIp, serverName: serverNameToSend, discordId }]);
        })
        .catch(error => console.error('Error adding IP: ', error));
    };

    const removeIp = (ipToRemove) => {
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/products/ips`, { data: { discordId, productName: decodedProductName, ip: ipToRemove }, withCredentials: true, credentials: 'include' })
        .then(response => {
            setIps(ips.filter(ip => ip.ip !== ipToRemove));
        })
        .catch(error => console.error('Error removing IP: ', error));
    };

    return (
        <div className="ProductIpPage">
            <BackHeader />

            <main className='profile-content'>
                <h2 className="profile-title">{decodedProductName}</h2>
                <div className="ip-block">
                    <IpForm addIp={addIp}/>
                    <div className="profile-divider"></div>
                    <IpList ips={ips} removeIp={removeIp} />
                </div>
            </main>

            <Footer/>
        </div>
    );
}

export default ProductIpPage;