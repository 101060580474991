import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import './Header.css';

function Header() {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/logout`, {withCredentials: true, credentials: 'include'})
            .then(logOutResponse => {
                if (logOutResponse.data.redirectUrl) {
                    window.location.href = logOutResponse.data.redirectUrl;
                }
            })
            .catch(error => {
                console.error('Error on exit: ', error);
            });
    };

    return (
        <header className="header">
            <div className="header-container">
                <a href="https://lowner.dev">
                    <img src="/data/images/lownerLogo.png" alt="Lowner Logo" className="header-logo" />
                </a>
                <nav className={`nav ${isOpen ? 'open' : ''}`}>
                    <a className="header-button" href="https://discord.gg/Hg9ZFn3Avb" target="_blank" rel="noopener noreferrer">Discord</a>
                    <span onClick={handleLogout} className="header-button logout">{t('Logout')}</span>
                </nav>
                <div className="menu-icon" onClick={toggleMenu}>
                    <div className="menu-bar"></div>
                    <div className="menu-bar"></div>
                    <div className="menu-bar"></div>
                </div>
            </div>
        </header>
    );
}

export default Header;