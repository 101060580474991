import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import './ProductsModule.css';

function ProductsModule() {
    const { t } = useTranslation();

    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [discordLinked, setDiscordLinked] = useState(false);

    useEffect(() => {
        if(isLoading)
        {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/discord`, { withCredentials: true, credentials: 'include'})
                .then(response => {
                    if (response.data.discordUsername) {
                        setDiscordLinked(true);
                        fetchProducts();
                    } else {
                        setDiscordLinked(false);
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                    setIsLoading(false);
                });
        }
    }, []);

    function fetchProducts() {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/products`, { withCredentials: true, credentials: 'include'})
            .then(response => {
                setProducts(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching products:', error);
                setIsLoading(false);
            });
    }

    if (isLoading) { return (
        <div className="products-container">
            <div className="InformationText">{t('LoadingProduct')}</div>
        </div>
    );}
    
    if (!discordLinked) { return (
        <div className="products-container">
            <div className="InformationText">{t('LinkDiscordInfo')}</div>
        </div>
    );}

    if (products.length === 0) { return (
        <div className="products-container">
            <div className="InformationText">{t('NoProductsFound')}</div>
        </div>
    );}

    return (
        <div className="products-container">
            {products.map(product => (
                <div key={product._id} className="Product" style={{ borderBottom: '3px solid ' + product.color || '3px solid #fff' }}>
                    <a href={product.productUrl} target="_blank" rel="noopener noreferrer"><img src={product.imageUrl} alt={product.name} className="ProductImage"/></a>
                    <div className="ProductButtonsGroup">
                        <a href={product.downloadUrl} target="_blank" rel="noopener noreferrer" className="ProductDownloadButton"><i className="fa-brands fa-google-drive ProductButtonIcon"/>&nbsp;<b className="ProductButtonText">{t('DownloadButton')}</b></a>
                        <Link to={`/profile/${encodeURIComponent(product.name)}`} className="ProductIPButton" style={{ background: product.color || '#fff' }}><i className="fa-solid fa-server ProductButtonIcon"/>&nbsp;<b className="ProductButtonText">{t('ProtectionButton')}</b></Link>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ProductsModule;