import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './IpForm.css';

function IpForm({ addIp }) {
    const { t } = useTranslation();
    const [newIp, setNewIp] = useState('');
    const [newIpName, setNewIpName] = useState('');

    const handleAddIp = () => {
        addIp(newIpName, newIp);
        setNewIp('');
        setNewIpName('');
    };

    return (
        <div className="ip-form-container">
            <p className="ip-form-info">{t('IpPageInfo')}</p>
            <div className="ip-form">
                <input
                    type="text"
                    className="input-field server-name"
                    value={newIpName}
                    onChange={(e) => setNewIpName(e.target.value)}
                    placeholder={t('ServerNameField')}
                />
                <input
                    type="text"
                    className="input-field ip-address"
                    value={newIp}
                    onChange={(e) => setNewIp(e.target.value)}
                    placeholder={t('IpAddressFild')}
                />
                <button className="fa-plus add-button" onClick={handleAddIp}></button>
            </div>
        </div>
    );
}

export default IpForm;