import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './BackHeader.css';

function BackHeader() {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="header">
            <div className="header-container">
                <a href="https://lowner.dev">
                    <img src="/data/images/lownerLogo.png" alt="Lowner Logo" className="header-logo" />
                </a>
                <nav className={`nav ${isOpen ? 'open' : ''}`}>
                    <a href="/profile" className="header-button">{t('BackButton')}</a>
                </nav>
                <div className="menu-icon" onClick={toggleMenu}>
                    <div className="menu-bar"></div>
                    <div className="menu-bar"></div>
                    <div className="menu-bar"></div>
                </div>
            </div>
        </header>
    );
}

export default BackHeader;