import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p className="footerInfo">© {(new Date().getFullYear())} Lowner.dev. All rights reserved.</p>
                <div className="social-icons">
                    <a target="_blank" rel="noopener noreferrer" href='https://github.com/EvgenyN-Lowner'><i className="footerMain-Icons fa-brands fa-github fa-xl"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href='https://steamcommunity.com/id/Evgeny__N/myworkshopfiles/?appid=221100'><i className="footerMain-Icons fa-brands fa-steam fa-xl"></i></a>
                </div>
            </div>
            <div className="legal-info">
                <p>
                    Lowner.dev is not affiliated or authorized by Bohemia Interactive a.s. Bohemia Interactive, ARMA, DAYZ and all associated logos and designs are trademarks or registered trademarks of Bohemia Interactive a.s.
                </p>
            </div>
        </footer>
    );
}

export default Footer;