import React from 'react';
import { useTranslation } from 'react-i18next';

import './IpList.css';

function IpList({ ips, removeIp }) {
    const { t } = useTranslation();

    return (
        <div className="ip-list-container">
            {ips.length > 0 ? (
                ips.map((ip, index) => (
                    <div key={index} className="ip-list-item">
                        <div className="ip-list-index">
                            {index + 1}.
                        </div>
                        <input
                            type="text"
                            className="ipList-field server-name"
                            value={ip.serverName}
                            readOnly
                        />
                        <input
                            type="text"
                            className="ipList-field ip-address"
                            value={ip.ip}
                            readOnly
                        />
                        <button
                            onClick={() => removeIp(ip.ip)}
                            className="fa-solid fa-trash remove-button"
                        ></button>
                    </div>
                ))
            ) : (
                <p className="InformationText">{t('NoIPsFound')}</p>
            )}
        </div>
    );
}

export default IpList;