import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Header from './Header';
import Footer from './Footer';

import DiscordLogin from './DiscordLogin';
import ProductsModule from './ProductsModule';

import './Profile.css'

function Profile() {
    const { t } = useTranslation();

    const [user, setUser] = useState(null);

    useEffect(() => {
        document.title = 'User Profile';

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/profile`, { withCredentials: true, credentials: 'include'})
            .then(response => {
                if (response.data.redirectUrl) {
                    window.location.href = response.data.redirectUrl;
                }
                else
                {
                    setUser(response.data);
                }
            })
            .catch(error => {
                console.error('Error getting profile data: ', error);
            });
    }, []);

    if(!user)
    {
        return (
            <div className="profile-page">
                <Header />

                <main className='profile-content'>
                    <h2 className="profile-title">{t('UserProfile')}</h2>
                    <div className="profile-block">
                        <div className='InformationText'>{t('LoadingProfile')}</div>
                    </div>
                </main>

                <Footer />
            </div>
        );
    }

    return (
        <div className="profile-page">
            <Header />

            <main className='profile-content'>
                <h2 className="profile-title">{t('UserProfile')}</h2>
                <div className="profile-block">
                    <div className="profile-info">
                        <a href={user.profileUrl} target="_blank" rel="noopener noreferrer" ><img src={user.avatarUrl} alt="userAvatar" className='profile-avatar'/></a>
                        <div className="profile-details">
                            <p className="profile-username">{user.username}</p>
                            <p className='steamIdText'>Steam64 ID: {user.steamId}</p>
                            <DiscordLogin/>
                        </div>
                    </div>
                    <div className="profile-divider"></div>
                    <div>
                        <ProductsModule/>
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
}

export default Profile;