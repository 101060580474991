import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Profile from './components/Profile';
import HomePage from './components/HomePage';
import ProductIPPage from './components/ProductIPPage'

import './i18n';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/:productName" element={<ProductIPPage />} />
      </Routes>
    </Router>
  );
}

export default App;