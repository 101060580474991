import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      "Welcome to": "Welcome to",
      "To continue": "To continue, log in using your Steam account.",
      "SteamLogin": "Login With Steam",
      "Redirection": "Redirecting...",
      "Logout": "Logout",
      "UserProfile": "User profile",
      "LoadingProfile": "Loading profile...",
      "LoadingProduct": "Loading...",
      "LinkDiscordInfo": "To display your mods, please link your Discord account.",
      "NoProductsFound": "You have no mods available.",
      "DownloadButton": "Download",
      "ProtectionButton": "Protection",
      "LinkYourDiscordButton": "Link your Discord",
      "BackButton": "Back",
      "ServerNameField": "Server name",
      "IpAddressFild": "IP address",
      "NoIPsFound": "You haven't added any IP addresses yet.",
      "IpPageInfo": "Please enter your IPv4 address without port. The server name can be anything, not necessarily the real server name."
    }
  },
  ru: {
    translation: {
        "Welcome to": "Добро пожаловать на",
        "To continue": "Чтобы продолжить, войдите, используя свой Steam аккаунт.",
        "SteamLogin": "Войти через Steam",
        "Redirection": "Перенаправление...",
        "Logout": "Выйти",
        "UserProfile": "Профиль пользователя",
        "LoadingProfile": "Загрузка профиля...",
        "LoadingProduct": "Загрузка...",
        "LinkDiscordInfo": "Чтобы отобразить ваши моды, привяжите свою учетную запись Discord.",
        "NoProductsFound": "У вас нет доступных модов.",
        "DownloadButton": "Скачать",
        "ProtectionButton": "Защита",
        "LinkYourDiscordButton": "Привязать Discord",
        "BackButton": "Назад",
        "ServerNameField": "Название сервера",
        "IpAddressFild": "IP-адрес",
        "NoIPsFound": "Вы еще не добавили IP-адреса.",
        "IpPageInfo": "Введите ваш IPv4-адрес без порта. Название сервера может быть любым, не обязательно реальным названием сервера."
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;