import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import './DiscordLogin.css'

function DiscordLogin() {
    const { t } = useTranslation();

    const [user, setUser] = useState(null);

    useEffect(() => {
        if(!user || !user.discordUsername || !user.discordId)
        {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/discord`, { withCredentials: true, credentials: 'include'})
            .then(response => {
                setUser(response.data);
            })
            .catch(error => {
                console.error('Error getting Discord data: ', error);
            });
        }
    });

    const bindDiscord = () => {
        const redirectUri = encodeURIComponent(process.env.REACT_APP_DISCORD_REDIRECT_URL);
        console.log(redirectUri);
        const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=identify`;

        window.location.href = discordAuthUrl;
    };

    return (
        <p>
            {user && user.discordUsername && user.discordId ? (
                <span>Discord: {user.discordUsername} ({user.discordId})</span>
            ) : (
                <button onClick={bindDiscord} className="discordButton"><span>{t('LinkYourDiscordButton')}</span><div className="icon">
		        <i className="fa-brands fa-discord"></i>
                </div></button>
            )}
        </p>
    );
}

export default DiscordLogin;